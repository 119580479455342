/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body, #root {
  font-family: 'Ubuntu', sans-serif;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;
}